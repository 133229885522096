import { ref } from '@vue/composition-api'
import store from '@/store'

export default function addProduct() {
  const isReserved = ref(false)
  const isSpecial = ref(false)
  const isActive = ref(true)
  const isFree = ref(false)
  const isBanner = ref(false)
  const slug = ref('')
  const startTime = ref('')
  const endTime = ref('')
  const date = ref('')
  const type = ref(null)
  const category = ref(null)
  const weekDayAvailables = ref(store.state.app.weekDayAvailableOptions)
  const imgID = ref(null)
  const arrayPrograms = ref([
    {
      name: '',
      languageCode: '',
      subTitle: '',
      description: '',
      specialDetail: '',
    },
  ])
  const countPrograms = ref(1)
  store.dispatch('program/fetchCategories')
  return {
    arrayPrograms,
    countPrograms,
    isReserved,
    startTime,
    endTime,
    date,
    type,
    imgID,
    category,
    isSpecial,
    isActive,
    isBanner,
    isFree,
    slug,
    weekDayAvailables,
  }
}
